<template>
    <div>
        <div class="mx-0 d-flex pb-2">
            <div class="align-center">
                <p style="font-size: 18px;"
                   class="font-weight-semibold text-black ml-5">
                    {{ $t('My Profile') }}
                </p>

            </div>
        </div>
        <v-divider class="mt-n2"></v-divider>
        <v-form class="multi-col-validation"
                lazy-validation
                ref="form"
                v-model="valid">
            <v-card class="pa-3 mt-7">
                <v-card-text class="d-flex align-center flex-wrap pb-0">
                    <div class="d-flex align-center pb-5">
                        <div class="d-flex align-center">
                            <span class="font-weight-semibold text-base text-green">
                                {{ $t('Profile') }}
                            </span>
                        </div>
                    </div>

                    <v-spacer></v-spacer>

                    <div class="d-flex align-center pb-5">

                    </div>
                </v-card-text>

                <!-- <v-divider></v-divider> -->
                <v-card-text>
                    <v-row class="">
                        <v-col cols="12"
                               lg="5"
                               md="5"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Name') }}
                            </p>
                            <v-text-field variant="underlined"
                                          hide-details
                                          
                                          :rules="genericRules"
                                          :disabled="true"
                                          class="text-fields"
                                          v-model="user.username"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="5"
                               md="5"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Email ID') }}
                            </p>
                            <v-text-field variant="underlined"
                                          hide-details
                                          
                                          :rules="genericRules"
                                          :disabled="true"
                                          class="text-fields"
                                          v-model="user.email"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="5"
                               md="5"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Contact Number') }}
                            </p>
                            <v-text-field variant="underlined"
                                          
                                          :rules="numberRule"
                                          v-mask="'XXXXXXXXXX'"
                                          :disabled="true"
                                          class="text-fields"
                                          v-model="user.phone"></v-text-field>
                        </v-col>

                        <!-- <v-col cols="12"
                               v-if="type == 'Owner'"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Password') }}
                            </p>
                            <v-text-field variant="underlined"
                                          hide-details
                                          class="text-fields"
                                          v-model="user.password"></v-text-field>
                        </v-col> -->

                        <!-- <v-col cols="12"
                               v-if="type == 'Owner'"
                               md="6"
                               lg="5"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Confirm Password') }}
                            </p>
                            <v-text-field variant="underlined"
                                          hide-details
                                          class="text-fields"
                                          v-model="user.confirm"></v-text-field>
                        </v-col> -->

                        <!-- <v-col cols="12"
                               lg="5"
                               md="5"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Role') }}
                            </p>
                            <v-select :items="roleOptions"
                                      :label="t('Role')"
                                      variant="underlined"
                                      hide-details
                                      item-value="id"
                                      item-text="role_name"
                                      class="text-fields"
                                      :rules="genericRules"
                                      v-model="user.role_id"></v-select>
                        </v-col> -->

                        <!-- <v-col cols="12"
                           md="6"
                           sm="12">
                           <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('Name') }}
                        </p>
                        <v-text-field :label="t('Password')"
                                      variant="underlined"
                                      hide-details
                                      class="text-fields"
                                      :rules="genericRules"
                                      v-model="user.password"></v-text-field>
                    </v-col> -->
                    </v-row>
                    <!-- <div class="mt-5">
                        <v-btn color="primary"
                               class="text-lower owner-info-btn border-card"
                               @click="submit">
                            Submit
                        </v-btn>
                    </div> -->
                </v-card-text>
            </v-card>
        </v-form>


        <!-- <v-card class="pa-3 mt-4">
            <v-card-text class="d-flex align-center flex-wrap pb-0">
                <div class="d-flex align-center pb-5">
                    <div class="d-flex align-center">
                        <span class="font-weight-semibold text-base text-green">Update Password</span>
                    </div>
                </div>

                <v-spacer></v-spacer>

                <div class="d-flex align-center pb-5">

                </div>
            </v-card-text>

            <v-card-text>
                <v-row class="">
                    <v-col cols="12"
                           lg="5"
                           md="6"
                           sm="12">
                        <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('Create New Password') }}
                        </p>
                        <v-text-field :label="t('Password')"
                                      variant="underlined"
                                      hide-details
                                      class="text-fields"
                                      :rules="genericRules"
                                      v-model="user.password"></v-text-field>
                    </v-col>
                    <v-col cols="12"
                           md="6"
                           lg="5"
                           sm="12">
                        <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('Confirm Password') }}
                        </p>
                        <v-text-field :label="t('Password')"
                                      variant="underlined"
                                      hide-details
                                      class="text-fields"
                                      :rules="genericRules"
                                      v-model="user.password"></v-text-field>
                    </v-col>
                </v-row>
                <div class="mt-5">
                    <v-btn color="primary">
                        Update Password
                    </v-btn>
                </div>
            </v-card-text>
        </v-card> -->
    </div>
</template>

<script>
import router from '@/router';

import { useUtils as useAclUtils } from '@core/libs/acl';
import { useUtils } from '@core/libs/i18n';

import userServices from '@/services/user-services';

export default {
    data: () => ({
        valid: false,
        view: false,
        state: 'Add New',
        genericRules: [
            v => !!v || 'Field is required'
        ],

        numberRule: [
            value => !!value || 'Phone Number is required.',
            value => (value && value.length >= 10) || 'Number must be 10 digits.',
        ],

        roleOptions: [
            // { id: 1, role_name: 'admin' },
            { id: 2, role_name: 'Approver' },
            { id: 3, role_name: 'Initiator' }
        ],

        user: {
            email: '',
            password: '',
            username: '',
            phone: '',
            role_id: '',
            confirm: ''
        },

        route: router.currentRoute.params.id,
        state: router.currentRoute.params?.state,
        type: router.currentRoute.params.type

    }),
    mounted()
    {
        this.getUser();


    },
    methods: {
        backToList()
        {

            // console.log(this.type)
            if (this.type == 'Owner')
            {
                router.push({ name: 'owner-list' })
            }
            if (this.type == 'User')
            {
                router.push({ name: 'user-list' })
            }
            if (this.type == 'Admin')
            {
                router.push({ name: 'admin-list' })
            }

        },
        getUser()
        {
            
            userServices.viewUser().then(resp =>
            {
                // console.log(resp);
                if (resp.statusCode == 200)
                {
                    this.user.email = resp.data.user.email;
                    this.user.username = resp.data.user.first_name;
                    this.user.phone = resp.data.user.phone;
                }
            })
        },
        submit()
        {
            
            // console.log('submit', this.user);
            this.$refs.form.validate();
            if (this.$refs.form.validate())
            {
                let obj = {
                    "first_name": this.user.username,
                    "phone": this.user.phone,
                    "email": this.user.email
                }
                userServices.updateProfile(obj).then(res =>
                {
                    if (res.statusCode == 200)
                    {
                        this.$swal({
                            title: this.type,
                            text: `updated successfully.`,
                            confirmButtonColor: '#00b2a9',
                            icon: 'success',
                        }).then((result) =>
                        {

                        });
                    }
                    else
                    {
                        this.$swal({
                            icon: 'error',
                            title: 'User',
                            confirmButtonColor: '#00b2a9',
                            text: 'Something went wrong!',
                        })
                    }
                });



            }
        },
    },
    setup()
    {
        const { t } = useUtils()
        const { canViewVerticalNavMenuLink } = useAclUtils()

        return {
            // i18n
            t,

            // ACL
            canViewVerticalNavMenuLink,
        }
    },
}
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 4px;
}

.only-divider .v-divider {
    border-width: 1px !important;
}

.text-blue {
    color: #006fcf;
}

.text-black {
    color: #000;
}

.bg-white {
    background: white;
}

.v-expansion-panel {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

.v-divider {
    border-color: #FFFFFF !important;
}

.text-fields {
    margin: -4px 0px 0px;
}
</style>
